import { ArrowPathIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import React from "react";
import { fancyConfirm } from "./fancy-confirm";

const classNames = {
    "bg-white": "text-gray-400 hover:text-gray-500 hover:bg-gray-100",
    "bg-gray-100": "text-gray-500 hover:text-gray-600 hover:bg-gray-200",
    "bg-gray-200": "text-gray-600 hover:text-gray-700 hover:bg-gray-300",
    "bg-gray-300": "text-gray-700 hover:text-gray-800 hover:bg-gray-400",
} as const;

const fixedClassNames = "p-1 rounded cursor-pointer";

interface DeleteButtonProps {
    /**
     * Color button is displayed on top of. Will determine downstream colors.
     * If you receive a type error, add the color to the `classNames` object.
     */
    backgroundColor: keyof typeof classNames;

    /**
     * If set, will show confirm dialog before callback
     */
    confirm?: {
        title: string;
        message: string;
        confirmLabel?: string;
        cancelLabel?: string;
    };

    size?: "sm" | "md";

    /**
     * Callback to call when button is clicked, or if confirm is set, after confirm dialog is accepted
     */
    onClick: () => void;
}

export const DeleteButton: React.FC<DeleteButtonProps> = props => {
    const { backgroundColor, confirm, onClick: originalOnClick } = props;
    const onClick = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            if (e && e.preventDefault) e.preventDefault();
            if (confirm) {
                const { title, message, confirmLabel, cancelLabel } = confirm;
                const shouldContinue = await fancyConfirm(
                    title,
                    message,
                    confirmLabel ?? "Yes, delete",
                    cancelLabel ?? "Cancel",
                );
                if (shouldContinue) {
                    originalOnClick();
                }
            } else {
                originalOnClick();
            }
        },
        [confirm, originalOnClick],
    );
    let sizeClassNames = "w-7 h-7";

    if (props.size) {
        switch (props.size) {
            case "sm":
                sizeClassNames = "w-6 h-6";
                break;
        }
    }

    return (
        <button type="button" onClick={onClick} aria-label="Delete">
            <TrashIcon
                aria-hidden="true"
                className={`${sizeClassNames} ${fixedClassNames} ${classNames[backgroundColor]}`}
            />
        </button>
    );
};

interface EditButtonProps {
    /**
     * Color button is displayed on top of. Will determine downstream colors.
     * If you receive a type error, add the color to the `classNames` object.
     */
    backgroundColor: keyof typeof classNames;
    onClick: () => void;
    size?: "sm" | "md";
    tabindex?: number;
}

export const EditButton: React.FC<EditButtonProps> = props => {
    const { backgroundColor, tabindex, onClick } = props;

    const handleClick = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            if (e && e.preventDefault) e.preventDefault();
            if (onClick) {
                onClick();
            }
        },
        [onClick],
    );

    let sizeClassNames = "w-7 h-7";

    if (props.size) {
        switch (props.size) {
            case "sm":
                sizeClassNames = "w-6 h-6";
                break;
        }
    }

    const additionalProps: { tabindex?: number } = {};
    if (tabindex !== undefined) {
        additionalProps.tabindex = tabindex;
    }

    return (
        <button onClick={handleClick} aria-label="Edit" {...additionalProps}>
            <PencilIcon
                aria-hidden="true"
                className={`${sizeClassNames} ${fixedClassNames} ${classNames[backgroundColor]}`}
            />
        </button>
    );
};

interface RestoreButtonProps {
    /**
     * Color button is displayed on top of. Will determine downstream colors.
     * If you receive a type error, add the color to the `classNames` object.
     */
    backgroundColor: keyof typeof classNames;
    onClick: () => void;
    size?: "sm" | "md";
}

export const RestoreButton: React.FC<RestoreButtonProps> = props => {
    const { backgroundColor, onClick } = props;

    const handleClick = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            if (e && e.preventDefault) e.preventDefault();
            if (onClick) {
                onClick();
            }
        },
        [onClick],
    );

    let sizeClassNames = "w-7 h-7";

    if (props.size) {
        switch (props.size) {
            case "sm":
                sizeClassNames = "w-6 h-6";
                break;
        }
    }

    return (
        <button onClick={handleClick} aria-label="Edit">
            <ArrowPathIcon
                aria-hidden="true"
                className={`${sizeClassNames} ${fixedClassNames} ${classNames[backgroundColor]}`}
            />
        </button>
    );
};

interface IconButtonProps {
    /**
     * Color button is displayed on top of. Will determine downstream colors.
     * If you receive a type error, add the color to the `classNames` object.
     */
    backgroundColor: keyof typeof classNames;
    onClick: () => void;
    size?: "sm" | "md";
    Icon: React.ElementType;
    tabIndex?: number;
}

export const IconButton: React.FC<IconButtonProps> = props => {
    const { tabIndex, backgroundColor, onClick, Icon } = props;

    const handleClick = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            if (e && e.preventDefault) e.preventDefault();
            if (onClick) {
                onClick();
            }
        },
        [onClick],
    );

    let sizeClassNames = "w-7 h-7";

    if (props.size) {
        switch (props.size) {
            case "sm":
                sizeClassNames = "w-6 h-6";
                break;
        }
    }

    const additionalProps: { tabIndex?: number } = {};
    if (tabIndex !== undefined) {
        additionalProps.tabIndex = tabIndex;
    }

    return (
        <button onClick={handleClick} aria-label="Edit" {...additionalProps}>
            <Icon
                aria-hidden="true"
                className={`${sizeClassNames} ${fixedClassNames} ${classNames[backgroundColor]}`}
            />
        </button>
    );
};
