import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import {
    ExclamationTriangleIcon,
    QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import ReactDOM from "react-dom/client";
import { breakNewlines } from "util/text";
import { Checkbox } from "./checkbox";
import { PrimaryButton } from "./primary-button";
import { SecondaryButton } from "./secondary-button";

type ConfirmStyle = "alert" | "question";

interface Props {
    title: string;
    prompt: string;
    confirmLabel: string;
    cancelLabel: string;
    yes: () => void;
    no: () => void;
    confirmStyle: ConfirmStyle;
    extraConfirm?: boolean;
}

const ConfirmDialog: React.FC<Props> = props => {
    const {
        yes,
        no,
        title,
        prompt,
        confirmLabel,
        cancelLabel,
        confirmStyle,
        extraConfirm,
    } = props;
    const [confirmEnabled, setConfirmEnabled] = React.useState(!extraConfirm);
    const [isHidden, setIsHidden] = React.useState(false);
    const confirm = React.useCallback(() => {
        yes();
        setIsHidden(true);
    }, [yes]);
    const deny = React.useCallback(() => {
        no();
        setIsHidden(true);
    }, [no]);

    return (
        <Transition show={!isHidden} appear={true}>
            <Dialog as="div" className="relative z-1500" onClose={close}>
                <TransitionChild
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed z-1500 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <DialogPanel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    {confirmStyle === "alert" ? (
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon
                                                className="h-6 w-6 text-red-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    ) : null}
                                    {confirmStyle === "question" ? (
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-brand-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <QuestionMarkCircleIcon
                                                className="h-6 w-6 text-brand-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    ) : null}
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <DialogTitle
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900">
                                            {title}
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {breakNewlines(prompt)}
                                            </p>
                                        </div>
                                        {extraConfirm ? (
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-900 font-bold">
                                                    <Checkbox
                                                        checked={confirmEnabled}
                                                        onChange={e =>
                                                            setConfirmEnabled(
                                                                e.target.checked,
                                                            )
                                                        }
                                                        label="Yes, I really want to proceed."
                                                    />
                                                </p>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                    {confirmEnabled ? (
                                        <PrimaryButton onClick={confirm}>
                                            {confirmLabel}
                                        </PrimaryButton>
                                    ) : (
                                        <SecondaryButton disabled>
                                            {confirmLabel}
                                        </SecondaryButton>
                                    )}
                                    <SecondaryButton onClick={deny}>
                                        {cancelLabel}
                                    </SecondaryButton>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

interface FancyConfirmOptions {
    confirmStyle?: ConfirmStyle;
    /**
     * extraConfirm adds an extra action required to confirm the action.
     */
    extraConfirm?: boolean;
}

export const fancyConfirm = async (
    title: string,
    prompt: string,
    confirmLabel: string,
    cancelLabel: string,
    options: FancyConfirmOptions = {},
): Promise<boolean> => {
    const { confirmStyle = "alert", extraConfirm = false } = options;
    const wrapper = document.body.appendChild(document.createElement("div"));
    const root = ReactDOM.createRoot(wrapper);
    const promise = new Promise<boolean>(resolve => {
        const yes = () => resolve(true);
        const no = () => resolve(false);
        root.render(
            <ConfirmDialog
                yes={yes}
                no={no}
                title={title}
                prompt={prompt}
                confirmLabel={confirmLabel}
                cancelLabel={cancelLabel}
                confirmStyle={confirmStyle}
                extraConfirm={extraConfirm}
            />,
        );
    });
    const result = await promise;
    root.unmount();
    setTimeout(() => wrapper.remove());
    return result;
};
